.tooltip .tooltip-content {
  display: none;
  position: absolute;
  z-index: 1;
  background-color: #f9f9f9;
  border: 1px solid #dcdcdc;
  padding: 10px;
  border-radius: 4px;
  min-width: 330px;
  top: 50%;
  right: 100%;
  transform: translate(350px, 20px);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.tooltip:hover .tooltip-content {
  display: block;
}

@media screen and (max-width: 750px) {
  .tooltip .tooltip-content {
    right: 100%;
    top: 50%;
  }

  .tooltip-on-focus .tooltip .tooltip-content {
    transform: translate(-50%, -50%);
  }
}
