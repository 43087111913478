.filter-combinations .save-filters-button {
  margin-top: 15px;
}

.filter-combinations .filter-combinations-checkbox {
  margin-right: 10px;
}

.filter-combinations .filter-combinations-item {
  display: flex;
  align-items: center;
}

.filter-combinations .filter-combinations-item .filter-combinations-delete {
  fill: var(--gray-dark);
  cursor: pointer;
  width: 15px;
  height: 15px;
  display: none;
}

.filter-combinations .filter-combinations-item:hover .filter-combinations-delete {
  display: block;
}
