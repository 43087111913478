.settings .page-content-no-margin .title {
  font-size: 24px;
  font-weight: 400;
  color: var(--gray-dark);
  margin-bottom: 12px;
}

.settings .tier-cards {
  display: flex;
  justify-content: space-evenly;
  margin: 30px 0;
}

@media screen and (max-width: 1400px) {
  .settings .tier-cards {
    gap: 18px;
  }

  .settings .tier-cards .tier-card .content {
    padding: 18px;
  }
}

@media screen and (max-width: 700px) {
  .settings .tier-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .settings .tier-cards .tier-card {
    max-width: 300px;
  }
}

