.filter-combinations,
.property-filters {
  background-color: var(--white);
  border-radius: 8px;
  padding: 32px;
  height: fit-content;
}

.filter-combinations {
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.filter-combinations .filter-combinations-title-wrapper {
  display: flex;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--primary);
  cursor: pointer;
}

.filter-combinations .filter-combinations-title-wrapper .filter-combinations-title {
  font-size: 17px;
  font-weight: 500;
}

.filter-combinations .filter-combinations-title-wrapper .filter-combinations-caret {
  font-size: 25px;
  margin-left: auto;
}

.filter-combinations .filter-combinations-title-wrapper .filter-combinations-caret.open {
  transform: rotate(90deg);
}

.property-filters {
  display: flex;
  flex-direction: column;
  gap: 23px;
  max-height: calc(100vh - 98px - 30px - 145px - 50px - 30px);
  margin-bottom: 20px;
  overflow-y: auto;
}

.property-filters.mobile {
  overflow-y: unset;
  max-height: unset;
}


.property-filters .search-button svg {
  font-size: 20px;
  margin: auto 0 auto 8px;
}

.small-button {
  padding: 8px 12px;
}