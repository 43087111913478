.registration-upper-container {
  height: 100%;
}

.registration-container {
  justify-content: center;
  width: 100%;
  margin: auto 20px;
}

.registration-container .title {
  padding: 10px 0;
  font-weight: 400;
  font-size: 24px;
  color: var(--primary);
}

.registration-container .registration-form {
  max-width: 500px;
  width: 100%;
  padding: 75px;
  background-color: var(--white);
  border-radius: 25px;
}

.registration-container .registration-form .confirm-message {
  display: flex;
  margin-top: 24px;
  gap: 24px;
  padding: 6px;
  align-items: center;
}

@media (max-width: 600px) {
  .registration-container .registration-form .confirm-message {
    flex-direction: column;
  }
}

.registration-container .registration-form .confirm-message .checkmark svg {
  width: 50px;
  height: 50px;
}

.registration-form .form {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.registration-form .form .subtitle {
  font-weight: 400;
  font-size: 18px;
  padding-top: 8px;
  padding-bottom: 4px;
  color: var(--dark);
}

.registration-form .form .horizontal-input {
  display: flex;
  gap: 8px;
}

.registration-form .form .divider {
  margin: 8px 0;
}

@media (max-width: 600px) {
  .registration-upper-container {
    height: max-content;
  }

  .registration-container {
    height: min-content;
    margin: 30px;
  }

  .registration-container .registration-form {
    padding: 50px;
  }

  .registration-form .form .horizontal-input {
    flex-direction: column;
    gap: 4px;
  }

  .registration-form .form .phone-number {
    width: 100%;
    margin-right: 0;
  }
}
