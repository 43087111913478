.filters-outer-wrapper {
  width: 340px;
  min-height: 700px;
}

.properties-wrapper {
  display: flex;
}

.properties-list-and-grid,
.filters-wrapper {
  margin: 0 25px 25px 25px;
}

.properties-wrapper .properties-list-and-grid {
  flex: 1;
}

.filters-wrapper {
  width: 300px;
  position: fixed;
}

.properties-wrapper .filter-icon {
  font-size: 30px;
  color: var(--primary);
  margin-bottom: 20px;
  display: none;
  cursor: pointer;
}

@media screen and (max-width: 1350px) {
  .filters-wrapper {
    margin: 0 auto 20px;
    position: unset;
  }

  .filters-outer-wrapper .property-filters {
    padding: 20px;
  }

  .filters-outer-wrapper .filter-combinations {
    padding: 20px;
  }

  .properties-wrapper .filters-outer-wrapper.main {
    display: none;
  }

  .properties-wrapper .filter-icon {
    display: block;
  }
}

@media screen and (max-width: 1050px) {
  .properties-wrapper .properties-list-and-grid {
    margin-top: 0;
  }

  .properties-wrapper .layout-toggle {
    display: none;
  }
}
