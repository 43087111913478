.range-slider input[type="number"]::-webkit-outer-spin-button,
.range-slider input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.range-slider .number-input-group {
  width: 100%;
  display: flex;
  margin: 0 auto 15px;
  align-items: center;
}

.range-slider .number-input-group input {
  width: 100%;
  height: fit-content;
  outline: none;
  font-size: 14px;
  margin-left: 12px;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #999;
  -moz-appearance: textfield;
  appearance: textfield;
}

.range-slider .slider {
  height: 5px;
  position: relative;
  background: var(--background);
  border-radius: 5px;
  margin: 0 10px;
}


.range-slider .slider .progress {
  height: 100%;
  position: absolute;
  border-radius: 5px;
  background: var(--primary);
}

.range-slider .range-input-group {
  position: relative;
}

.range-slider .range-input-group input {
  position: absolute;
  width: calc(100% - 17px);
  height: 5px;
  top: -5px;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.range-slider .range-input-group input.range-max {
  left: 17px;
}

.range-slider .range-input-group input::-webkit-slider-thumb {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background: var(--primary);
  pointer-events: auto;
  -webkit-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  margin-top: -3px;
}

.range-slider .range-input-group input::-moz-range-thumb {
  height: 17px;
  width: 17px;
  border: none;
  border-radius: 50%;
  background: var(--primary);
  pointer-events: auto;
  -moz-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}
