@page { size: auto;  margin: 0mm; }

.description-hero {
}

@media (min-width: 1000px) {
  .description-hero {
    display: flex;
  }

  .description-hero .description {
    padding-left: 25px;
    padding-top: 0 !important;
  }
}

.listing-description.print .description-hero {
  display: unset;
}

.listing-description.print .description-hero .description {
  margin-top: 20px;
  padding: 0;
}

.listing-description.print .description-specifications .items,
.listing-description.print .description-location .items,
.listing-description.print .description-costs .items {
  flex-direction: column;
  width: 100%;
}

.listing-description.print .description-specifications .items .item,
.listing-description.print .description-location .items .item,
.listing-description.print .description-costs .items .item{
  width: 100%;
}

.description-hero .hero-slideshow {
  flex: 0.35;
  position: relative;
}

.description-hero .hero-slideshow .slider {
  position: relative;
}

.description-hero .hero-slideshow .slider .favourite {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: var(--primary);
  border-radius: 25px;
  padding: 2px 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.description-hero .hero-slideshow .slider .favourite svg {
  width: 20px;
  height: 20px;
}

.description-hero .hero-slideshow .slider .navigation {
  position: absolute;
  display: flex;
  bottom: 15px;
  right: 15px;
  background-color: var(--background);
  border-radius: 25px;
  padding: 2px 5px;
  cursor: pointer;
}

.description-hero .hero-slideshow .slider .navigation div {
  display: flex;
  align-items: center;
}

.description-hero .hero-slideshow .slider .navigation svg {
  width: 20px;
  height: 20px;
}

.description-hero .hero-slideshow .slider img {
  display: flex;
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 25px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  cursor: pointer;
}

.description-hero .description {
  display: flex;
  flex-direction: column;
  flex: 0.65;
  padding-top: 25px;
  justify-content: space-between;
}

.description-hero .description .description-content {
  max-height: 187px;
  padding-bottom: 10px;
  overflow: auto;
}

.description-hero .description .language-select-wrapper .language-select {
  margin-left: 12px;
  border: none;
  color: var(--primary);
  font-weight: 600;
  font-size: 16px;
  -webkit-appearance: none;
}

.description-hero .description .language-select-wrapper .language-select:focus,
.description-hero .description .language-select-wrapper .language-select:focus-visible {
  outline: none;
}

.description-hero .description .language-select-wrapper svg {
  width: 8px;
  height: 8px;
  margin-left: 6px;
}

.description-hero .description .information-block {
  display: flex;
  flex-wrap: wrap;
  padding: 18px 30px;
  gap: 15px;
  background-color: var(--background);
  border-radius: 10px;
}

.description-hero .description .information-block div {
  border-right: 1px solid var(--gray);
  padding-right: 15px;
  display: flex;
  align-items: center;
}

.description-hero .description .information-block .no-border-right {
  border-right: 0;
  padding-right: 0;
}

.description-hero .description .information-block .price {
  color: var(--primary);
  font-weight: 400;
}

.description-hero .description .information-block .map {
  background-color: white;
  border-radius: 25px;
  cursor: pointer;
}

.description-hero .description .information-block .map svg {
  margin: 2px 10px;
  width: 25px;
  height: 25px;
}

.description-hero .description .information-block div:first-child {
  padding-left: 0;
}

.description-hero .description .information-block div:last-child {
  padding-right: 0;
  border-right: none;
}

.description-hero .description .information-block .tooltip {
  position: relative;
}

.description-hero .description .information-block .tooltip-icon {
  cursor: pointer;
}

.description-hero .description .information-block .tooltip .tooltip-content {
  display: none;
  position: absolute;
  z-index: 1;
  background-color: #f9f9f9;
  border: 1px solid #dcdcdc;
  padding: 10px;
  border-radius: 4px;
  min-width: max-content;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.description-hero .description .information-block .tooltip:hover .tooltip-content {
  display: block;
}

.listing-description .header {
  color: var(--primary);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}

.listing-description p {
  color: var(--dark);
  font-weight: 300;
}

.listing-description .description-specifications .header {
  margin-top: 18px;
}

.listing-description .description-location .header,
.listing-description .description-costs .header {
  margin-top: 20px;
}

.listing-description .description-specifications .items,
.listing-description .description-location .items,
.listing-description .description-costs .items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 18px;
}

.listing-description .description-specifications .items .item,
.listing-description .description-location .items .item,
.listing-description .description-costs .items .item {
  display: flex;
  border-bottom: 1px solid var(--gray);
  padding: 15px 0;
  width: calc(100% / 4  - 18px);
  min-width: 300px;
}

.listing-description .print-images {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.listing-description .print-images .print-image {
  width: calc(50% - 30px);
  object-fit: contain;
  height: 300px;
}



@media (max-width: 1350px) {
  .listing-description .description-specifications .items .item,
  .listing-description .description-location .items .item,
  .listing-description .description-costs .items .item {
    width: calc(100% / 3  - 12px);
  }
}

@media (max-width: 1200px) {
  .listing-description .description-specifications .items .item,
  .listing-description .description-location .items .item,
  .listing-description .description-costs .items .item {
    width: calc(100% / 2  - 12px);
  }
}

@media (max-width: 950px) {
  .description-hero .description .description-content {
    max-height: unset;
    overflow: auto;
    padding-bottom: unset;
  }
}


@media (max-width: 900px) {
  .listing-description .description-specifications .items .item,
  .listing-description .description-location .items .item,
  .listing-description .description-costs .items .item {
    width: calc(100% / 1  - 12px);
  }
}

.listing-description .description-specifications .items .item .key,
.listing-description .description-location .items .item .key,
.listing-description .description-costs .items .item .key {
  flex: 1;
  text-align: start;
  color: var(--gray-dark);
  font-weight: 400;
}

.listing-description .description-specifications .items .item .value,
.listing-description .description-location .items .item .value,
.listing-description .description-costs .items .item .value {
  flex: 1;
  text-align: end;
  color: var(--primary);
  font-weight: 300;
  position: relative;
  margin-right: 5px;
}

.listing-description .description-specifications .items .item .value sup,
.listing-description .description-location .items .item .value sup,
.listing-description .description-costs .items .item .value sup {
  position: absolute;
  top: -3px;
  right: -7px;
}

@media screen and (max-width: 500px) {
  .description-hero .description .information-block .tooltip .tooltip-content {
    left: unset;
    right: 0;
    transform: unset;
  }
}
