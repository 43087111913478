.form {
  display: flex;
  flex-direction: column;
}

.form .title {
  color: var(--primary);
  font-weight: 300;
  font-size: 28px;
  margin-bottom: 6px;
}

.form .section {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 12px;
}

.form .section .subtitle {
  padding-top: 12px;
  font-weight: 400;
  font-size: 28px;
  color: var(--primary);
}

.form .section .row {
  display: flex;
  gap: 32px;
}

.form .section .half-row {
  display: flex;
  max-width: 50%;
}

@media screen and (max-width: 676px) {
  .form .section .row {
    flex-direction: column;
    gap: 6px;
  }
}

.form .section .half-row > * {
  padding-right: 16px;
}

.form .section .previews {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}