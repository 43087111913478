.agency-list {
}

.agency-list .navigation {
  display: flex;
}

.agency-list .header {
  display: flex;
  padding:  24px 12px 12px;
  border-bottom: 1px solid var(--gray-lighter);
}

.agency-list .header .items {
  flex: 1;
  display: flex;
}

.agency-list .header .item {
  flex: 1;
  color: var(--primary);
  font-weight: 500;
  display: flex;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.agency-list .user-item {
  display: flex;
  border-bottom: 1px solid var(--gray-lighter);
  transition: background-color 0.1s linear;
}

.agency-list .user-item:hover {
  background: var(--background);
}

.agency-list .user-item .content {
  flex: 1;
  display: flex;
  padding: 12px;
  min-height: 46px;
}

.agency-list .user-item .content .item {
  flex: 1;
  color: var(--dark);
  line-break: anywhere;
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.agency-list .user-item .content .item.bold {
  font-weight: 600;
}
