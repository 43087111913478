.profile-form .horizontal-row {
  display: flex;
  gap: 36px;
  margin-bottom: 4px;
}

.profile-form .half-row {
  display: flex;
  max-width: calc(50% - 18px);
}

@media screen and (max-width: 1000px) {
  .profile-form .horizontal-row {
    flex-direction: column;
    gap: 4px;
  }

  .profile-form .half-row {
    max-width: 100%;
  }
}