.multi-select {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.multi-select .show-button {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: 3px;
  color: var(--gray-dark);
  font-weight: 400;
  font-size: 14px;
  gap: 3px;
}

.multi-select .show-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.multi-select .sub-multi-select {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
