.property-filter .filter-name-wrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray);
  color: var(--dark);
  margin-bottom: 15px;
}

.property-filter .filter-name-wrapper.collapsable {
  cursor: pointer;
}

.property-filter .filter-name-wrapper .filter-name {
  font-size: 17px;
  font-weight: 500;
  margin-right: auto;
  user-select: none;
  -webkit-user-select: none;
}

.property-filter .filter-name-wrapper .filter-caret {
  font-size: 25px;
  fill: var(--dark);
  transition: all 0.15s ease-in-out;
}

.property-filter .filter-name-wrapper .filter-caret.collapsed {
  transform: rotate(90deg);
}
