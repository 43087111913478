.password-requirements {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.password-requirements .item {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 6px;
}

.password-requirements .item svg.success {
  fill: var(--green);
}