:root {
  --dark: #292929;
  --primary: #274642;
  --primary-lighter: rgba(44, 79, 76, 0.67);
  --gray: #cacaca;
  --gray-lighter: #e6e6e6;
  --gray-dark: #545454;
  --secondary: #d4d5d0;
  --secondary-lighter: rgba(212, 213, 208, 0.5);
  --white: #fff;
  --broken-white: #fafafa;
  --green: green;
  --pink: rgb(255, 235, 236);
  --red-lighter: rgba(222, 0, 0, 0.7);
  --red: #de0000;
  --red-darker: #b20000;
  --background: #f4f4f4;
  --alert-bg: rgb(247, 209, 213);
}

html {
  height: 100%;
}

html.no-scroll {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: var(--background);
  font-weight: 200;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Whyte", serif;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.relative {
  position: relative;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.align-self-center {
  align-self: center;
}

.align-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.m-4 {
  margin: 1rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 2rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-auto {
  margin-top: auto;
}

.px-1 {
  padding: 0 1rem;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.w-fit {
  width: fit-content;
}

.w-100 {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.button {
  border-radius: 20px;
  border: 0;
  padding: 8px 16px;
  background-color: var(--primary);
  color: var(--white);
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}

.button.disabled,
.button:disabled {
  background-color: var(--primary-lighter);
  cursor: not-allowed;
}

.button:hover {
  background-color: var(--primary-lighter);
  transition: all 0.1s linear;
}

.button-danger {
  background-color: var(--red);
}

.button-danger:hover {
  background-color: var(--red-darker);
}

.button-danger:disabled {
  cursor: not-allowed;
  background-color: var(--red-lighter);
}

.button-secondary {
  border-radius: 20px;
  border: 0;
  padding: 8px 16px;
  background-color: var(--background);
  color: var(--primary);
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
}

.button-secondary:hover {
  background-color: var(--gray-lighter);
  transition: all 0.1s linear;
}

.button.outlined {
  background-color: var(--white);
  color: var(--primary);
  border: 1px solid var(--primary);
}

.button.outlined:hover {
  background-color: var(--background);
}

.link {
  color: var(--primary);
}

.page-wrapper {
  width: 100%;
  max-width: 1500px;
}

.page {
  display: flex;
}

.page.without-side-navigation {
  display: block;
}

@media (min-width: 800px) {
  .page {
    margin: 0 25px 25px 25px;
    gap: 25px;
  }

  .page-content-no-margin {
    background-color: var(--white);
    border-radius: 8px;
    padding: 32px;
  }

  .page-content {
    background-color: var(--white);
    border-radius: 8px;
    padding: 32px;
    margin: 50px;
  }
}

@media (max-width: 799px) {
  .page {
    margin: 10px;
    gap: 10px;
  }

  .page-content-no-margin {
    background-color: var(--white);
    border-radius: 8px;
    padding: 10px;
  }

  .page-content {
    background-color: var(--white);
    border-radius: 8px;
    padding: 10px;
    margin: 10px;
  }
}

.white-padded-box {
  background-color: var(--white);
  border-radius: 8px;
  padding: 32px;
}

.hidden {
  display: none !important;
}

.height-fit {
  height: fit-content;
}

@media (min-width: 1001px) {
  .page .side-nav {
    flex: 0.2;
  }

  .page .page-content-no-margin {
    flex: 0.8;
  }
}

@media (max-width: 1000px) {
  .page {
    flex-direction: column;
  }

  .page .side-nav {
    flex: 1;
  }

  .page .page-content-no-margin {
    flex: 1;
  }
}

@font-face {
  font-family: "Whyte";
  src: url("public/fonts/Whyte-Regular.woff2") format("woff2"),
    url("public/fonts/Whyte-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Whyte";
  src: url("public/fonts/Whyte-Thin.woff2") format("woff2"),
    url("public/fonts/Whyte-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Whyte Book";
  src: url("public/fonts/Whyte-Book.woff2") format("woff2"),
    url("public/fonts/Whyte-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Whyte";
  src: url("public/fonts/Whyte-ExtraLightItalic.woff2") format("woff2"),
    url("public/fonts/Whyte-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Whyte";
  src: url("public/fonts/Whyte-Black.woff2") format("woff2"),
    url("public/fonts/Whyte-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Whyte";
  src: url("public/fonts/Whyte-Medium.woff2") format("woff2"),
    url("public/fonts/Whyte-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Whyte";
  src: url("public/fonts/Whyte-ExtraLight.woff2") format("woff2"),
    url("public/fonts/Whyte-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Whyte";
  src: url("public/fonts/Whyte-Regular.woff2") format("woff2"),
    url("public/fonts/Whyte-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Whyte";
  src: url("public/fonts/Whyte-Bold.woff2") format("woff2"),
    url("public/fonts/Whyte-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Whyte Super";
  src: url("public/fonts/Whyte-Super.woff2") format("woff2"),
    url("public/fonts/Whyte-Super.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Whyte";
  src: url("public/fonts/Whyte-LightItalic.woff2") format("woff2"),
    url("public/fonts/Whyte-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Whyte";
  src: url("public/fonts/Whyte-BlackItalic.woff2") format("woff2"),
    url("public/fonts/Whyte-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Whyte Book";
  src: url("public/fonts/Whyte-BookItalic.woff2") format("woff2"),
    url("public/fonts/Whyte-BookItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Whyte";
  src: url("public/fonts/Whyte-BoldItalic.woff2") format("woff2"),
    url("public/fonts/Whyte-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Whyte";
  src: url("public/fonts/Whyte-Light.woff2") format("woff2"),
    url("public/fonts/Whyte-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Whyte";
  src: url("public/fonts/Whyte-Heavy.woff2") format("woff2"),
    url("public/fonts/Whyte-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Whyte";
  src: url("public/fonts/Whyte-Italic.woff2") format("woff2"),
    url("public/fonts/Whyte-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Whyte";
  src: url("public/fonts/Whyte-HeavyItalic.woff2") format("woff2"),
    url("public/fonts/Whyte-HeavyItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Whyte";
  src: url("public/fonts/Whyte-MediumItalic.woff2") format("woff2"),
    url("public/fonts/Whyte-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Whyte Super";
  src: url("public/fonts/Whyte-SuperItalic.woff2") format("woff2"),
    url("public/fonts/Whyte-SuperItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Whyte";
  src: url("public/fonts/Whyte-ThinItalic.woff2") format("woff2"),
    url("public/fonts/Whyte-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Whyte Super";
  src: url("public/fonts/Whyte-Super.woff2") format("woff2"),
    url("public/fonts/Whyte-Super.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
