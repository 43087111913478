.tier-card {
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 500px;
  background-color: var(--white);
  border-radius: 6px;
  box-shadow: 0 0 6px 3px var(--gray-lighter);
  transition: all 0.1s linear;

}

.tier-card:hover {
  scale: 1.015;
}

.tier-card.current {
  box-shadow: 0 0 6px 3px var(--primary-lighter);
}

.tier-card.current.canceled {
  box-shadow: 0 0 18px 9px var(--gray);
}

.tier-card.recommended {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.tier-card .recommended-content {
  position: absolute;
  width: 100%;
  max-width: 320px;
  height: 30px;
  top: -30px;
  background-color: var(--primary);
  color: var(--white);
  box-shadow: 0 0 6px 3px var(--gray-lighter);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}

.tier-card .recommended-content:after {
  position: absolute;
  content: "";
  background-color: var(--white);
  height: 10px;
  width: 100%;
  top: 30px;
}

.tier-card .content {
  padding: 32px;
  height: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 640px) and (max-width: 870px) {
  .tier-card .content {
    padding: 12px;
  }
}

.tier-card .content .title {
  font-size: 24px;
  font-weight: 600;
  color: var(--gray-dark);
}

.tier-card .content .description {
  margin-top: 12px;
  font-size: 14px;
  color: var(--dark);
  font-weight: 400;
  min-height: 50px;
}

.tier-card .content .price-wrapper {
  display: flex;
  align-items: flex-end;
  gap: 6px;
}

.tier-card .content .price-wrapper .price {
  font-size: 42px;
  font-weight: 700;
}

.tier-card .content .price-wrapper .year {
  padding-bottom: 8px;
  font-weight: 400;
}

.tier-card .content .price-wrapper.small {
  color: var(--gray-dark);
  padding-left: 6px;
  gap: 3px;
}

.tier-card .content .price-wrapper.small .price {
  font-size: 16px;
  font-weight: 700;
}

.tier-card .content .price-wrapper.small .year {
  padding-bottom: 2px;
  font-size: 12px;
  font-weight: 400;
}

.tier-card .content .advantages {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 18px;
}

.tier-card .content .advantages .item {
  display: flex;
  gap: 6px;
  align-items: flex-start;
}

.tier-card .content .advantages .checkmark svg {
  width: 15px;
  height: 15px;
}

.tier-card .content .advantages .item .text {
  font-size: 14px;
}

.tier-card .content .get-started {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 18px;
  border-top: 1px solid var(--gray-lighter);
}

.tier-card .content .get-started .button {
  width: 100%;
}