.report-list {}

.report-list .navigation {
  display: flex;
}

.report-list .header {
  display: flex;
}

.report-list .header .items {
  flex: 1;
  display: flex;
  padding:  24px 12px 12px;
  border-bottom: 1px solid var(--gray-lighter);
}

.report-list .header .item {
  flex: 1;
  color: var(--primary);
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.report-list .header .item.small {
  flex: unset;
  width: 30px;
}

.report-list .header .item svg {
  margin-left: 5px;
  width: 20px;
  height: 20px;
}

.report-list .report-item {
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid var(--gray-lighter);
  transition: background-color 0.1s linear;
}

.report-list .report-item:hover {
  background-color: var(--background);
}

.report-list .report-item .content {
  flex: 1;
  display: flex;
  padding: 12px;
  min-height: 46px;
}

.report-list .report-item .content .item {
  flex: 1;
  color: var(--dark);
  line-break: anywhere;
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.report-list .report-item .content .item.small {
  flex: unset;
  width: 30px;
}

.report-list .report-item .content .item.user:hover {
  text-decoration: underline;
}