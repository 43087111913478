.payment-container {
  justify-content: center;
  width: 100%;
  margin: auto 20px;
}

.payment-upper-container {
  width: 100%;
  height: 100%;
}

.payment-form {
  max-width: 70%;
  width: 100%;
  padding: 75px;
  background-color: var(--white);
  border-radius: 25px;
}

.payment-logo {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.payment-back {
  color: var(--dark);
  text-decoration: none;
}

.payment-back:hover {
  text-decoration: underline;
}

@media screen and (max-width: 500px) {
  .payment-container {
    flex-direction: column;
  }
}

@media screen and (max-width: 1450px) {
  .payment-back {
    margin-top: 18px;
  }

}
