.header-links-mobile {
  display: flex;
  font-size: 21px;
}

.header-links-mobile .hamburger-menu,
.header-links-mobile .close-menu {
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-left: auto;
}

.header-links-mobile .links-overlay {
  position: absolute;
  padding: 30px 20px;
  top: 90px;
  left: 0;
  width: calc(100vw - 40px);
  height: calc(100vh - 130px);
  z-index: 100;
  background: var(--white);
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: auto;
}

.header-links-mobile .links-overlay .agency-select {
  width: 230px;
}

.header-links-mobile .links-overlay .agency-select select {
  font-size: 21px;
  color: var(--dark);
}

.header-links-mobile .icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  width: 45px;
  height: 45px;
}

.header-links-mobile .icon-wrapper svg {
  width: 38px;
  height: 38px;
  fill: var(--primary);
}

.header-links-mobile .icon-link.active .icon-wrapper {
  background: var(--gray);
  border-radius: 50%;
}

.header-links-mobile .icon-wrapper:hover {
  background: var(--gray);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.15s ease-in;
}

.header-links-mobile .link {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 400;
}

.header-links-mobile .link:hover,
.header-links-mobile .link.active {
  text-decoration: underline;
  transition: all 0.5s ease-in;
}

.header-links-mobile .account-section {
  border-top: 1px solid var(--gray);
  border-bottom: 1px solid var(--gray);
}

.header-links-mobile .links-section {
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.header-links-mobile .item {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .header-links-mobile .hamburger-menu,
  .header-links-mobile .close-menu {
    width: 30px;
  }
}
