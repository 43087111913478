.client-confirm-container {
  justify-content: center;
  width: 100%;
  margin: auto 20px;
}

.client-confirm-upper-container {
  height: 100%;
}

.client-confirm {
  max-width: 300px;
  padding: 75px;
  background-color: var(--white);
  border-radius: 25px;
}

.client-confirm svg {
  width: 100%;
}

.client-confirm .description {
  margin-top: 20px;
}

.client-confirm .description.error {
  background-color: var(--alert-bg);
  padding: 12px;
  color: var(--red-darker);
  border-radius: 6px;
}