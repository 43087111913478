.invoice-list {
}

.invoice-list .header {
  display: flex;
  border-bottom: 1px solid var(--gray-lighter);
}

.invoice-list .header .items {
  flex: 1;
  display: flex;
  padding: 24px 12px 12px;
}

.invoice-list .header .item {
  flex: 1;
  color: var(--primary);
  font-weight: 500;
  display: flex;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.invoice-list .invoice-item {
  display: flex;
  border-bottom: 1px solid var(--gray-lighter);
  transition: background-color 0.1s linear;
}

.invoice-list .invoice-item .content {
  flex: 1;
  display: flex;
  padding: 12px;
}

.invoice-list .invoice-item .content .item {
  flex: 1;
  color: var(--dark);
  line-break: anywhere;
  padding-right: 10px;
}

.invoice-list .invoice-item .content a {
  color: var(--gray-dark);
}
