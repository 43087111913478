.tier-selection-upper-container {
  height: 100%;
}

.tier-selection-container {
  justify-content: center;
  width: calc(100% - 40px);
  margin: auto 20px;
}

.tier-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.tier-selection svg {
  max-width: 400px;
}

.tier-selection .subtitle {
  text-align: center;
}


.tier-selection-upper-container .delete-account svg {
  cursor: pointer;
  width: 20px;
  height: 20px;
  fill: var(--primary);
}

.tier-selection .tier-cards {
  display: flex;
  gap: 38px;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
}

@media (max-width: 870px) {
  .tier-selection .tier-cards {
    gap: 12px;
  }
}

@media (max-width: 640px) {
  .tier-selection .tier-selection {
    padding-top: 48px;
  }

  .tier-selection .tier-cards {
    padding-bottom: 60px;
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
}

