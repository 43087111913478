.listing-header {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  gap: 15px;
}

.listing-header .extra-buttons {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.listing-header .contact-phone,
.listing-header .contact-email {
  background-color: var(--secondary-lighter);
  border-radius: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.1s linear;
}

.listing-header .contact-phone:hover,
.listing-header .contact-email:hover {
  background-color: var(--secondary);
}

.listing-header .edit-button svg,
.listing-header .client-button svg,
.listing-header .report-button svg {
  width: 18px;
  height: 18px;
  padding-right: 6px;
}

.listing-header .action-buttons {
  margin-left: auto;
  display: flex;
  gap: 1rem
}

@media screen and (max-width: 1210px) {
  .listing-header .action-buttons {
    margin-left: 0;
  }
}

.report-dialog-content .description, .subscription-dialog-content .description {
  margin-bottom: 15px;
}

.report-dialog-content textarea {
  width: 100%;
  height: 150px;
  resize: vertical;
  font-weight: 200;
  border: 1px solid var(--gray);
  border-radius: 10px;
}

.report-dialog-content .button-wrapper, .subscription-dialog-content .button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.report-dialog-content .submit-button {
  background-color: var(--primary);
  border-radius: 15px;
  color: var(--white);
  font-weight: 400;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
}

.report-dialog-content .submit-button.disabled {
  background-color: var(--primary-lighter);
  cursor: not-allowed;
}

.report-dialog-content .submit-button:hover {
  background-color: var(--primary-lighter);
}

.client-dialog-content {
  max-width: 900px;
}

.listing-header .property-reference {
  background-color: var(--primary);
  color: var(--secondary);
  border-radius: 25px;
  padding: 10px 25px 10px 25px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
}

.listing-header .property-reference .reference-item {
  display: flex;
  align-items: center;
}

.listing-header .property-reference .value {
  margin-left: 5px;
  font-weight: 600;
}

.listing-header .contact-phone svg,
.listing-header .contact-email svg,
.listing-header .view-count svg {
  width: 30px;
  height: 30px;
  padding: 8px;
  fill: var(--primary);
}

.listing-navigation {
  border-bottom: 1px solid var(--secondary);
  margin-bottom: 25px;
  display: flex;
  gap: 25px;
  overflow-x: auto;
}

.listing-navigation .item {
  font-size: 18px;
  padding-bottom: 15px;
  cursor: pointer;
  display: flex;
}

.listing-navigation .item.active {
  border-bottom: 5px solid var(--primary);
  color: var(--primary);
  font-weight: 500;
}

.listing-navigation .item.active:hover {
  border-bottom: 5px solid var(--primary);
  color: var(--primary);
}

.listing-navigation .item:hover {
  color: var(--primary);
  border-bottom: 1px solid var(--primary);
}

.listing-header .view-count {
  display: flex;
  align-items: center;
  font-size: 17px;
}
