.documents a {
  display: block;
  color: var(--dark);
  text-decoration: none;
  font-weight: 300;
  font-size: 16px;
  width: fit-content;
}

.documents a:hover {
  text-decoration: underline;
}