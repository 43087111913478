.side-navigation {
  min-width: 280px;
  width: 100%;
}

.side-navigation .item {
  display: flex;
  background-color: white;
  padding: 20px 30px;
  border-radius: 8px;
  align-content: center;
  margin: 0 0 8px;
  cursor: pointer;
  justify-content: space-between;
  transition: background-color 0.1s linear;
}

.side-navigation .item.disabled,
.side-navigation .item.disabled:hover {
  background-color: var(--gray);
  cursor: not-allowed;
}

.side-navigation .item.disabled:hover .title {
  color: var(--primary);
}

.side-navigation .item.active,
.side-navigation .item:hover {
  background-color: var(--primary);
}

.side-navigation .item svg {
  width: 25px;
  height: 25px;
  margin-right: 12px;
}

.side-navigation .item .title {
  text-decoration: none;
  color: var(--primary);
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: 300;
  word-break: break-all;
}

.side-navigation .item svg.arrow-right {
  width: 15px;
  height: 15px;
  margin: 0;
}

.side-navigation .item.active .title,
.side-navigation .item:hover .title {
  color: var(--background);
}

.side-navigation .item:hover svg * {
  stroke: var(--secondary);
}

@media screen and (max-width: 768px) {
  .side-navigation .item {
    padding: 10px 15px;
  }

  .side-navigation .item svg {
    width: 23px;
    height: 23px;
  }

  .side-navigation .item .title {
    font-size: 16px;
  }
}
