.navigation {
  display: flex;
  justify-content: flex-start;
}

.properties {
  margin-top: 25px;
  margin-bottom: 25px;
}

.navigation .pagination {
  background-color: var(--background);
  display: flex;
  border-radius: 25px;
  gap: 15px;
  padding: 5px 10px;
}

.navigation .pagination .icon {
  display: flex;
  align-items: center;
}

.navigation .pagination .icon svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.navigation .pagination .content {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--primary);
  font-weight: 500;
}

.navigation .pagination .content input {
  width: 40px;
  border-radius: 25px;
  box-shadow: none;
  border: none;
  background-color: var(--white);
  padding: 6px;
  text-align: center;
}

.navigation .pagination .content input[type="number"] {
  -webkit-appearance: textfield;
  appearance: textfield;
}
