.admin-statistics {
  gap: 25px;
}

.admin-statistics .statistics {
  display: flex;
  flex-wrap: wrap;
}

.admin-statistics .statistics .item {
  background-color: var(--background);
  border-radius: 8px;
  margin: 12px;
  padding: 15px;
  min-height: 150px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-statistics .statistics .item .title {
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  color: var(--primary);
}

.admin-statistics .statistics .item .value {
  font-size: 32px;
  text-align: center;
  color: var(--gray-dark);
}

@media (min-width: 1000px) {
  .admin-statistics .statistics .item {
    flex-basis: calc(33% - 90px);
  }
}

@media (max-width: 999px) {
  .admin-statistics .statistics .item {
    flex-basis: calc(100%);
    min-height: 80px;
  }

  .admin-statistics .statistics .item .title {
    font-size: 16px;
  }

  .admin-statistics .statistics .item .value {
    font-size: 28px;
  }
}
