.custom-button {
  color: var(--white);
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  height: auto;
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  user-select: none;
  -webkit-user-select: none;
  border: none;
  text-decoration: none;
  transition: background-color 0.1s linear;
}

.custom-button.primary {
  background-color: var(--primary);
}

.custom-button.primary:hover,
.custom-button.primary.disabled,
.custom-button.primary:disabled {
  background-color: var(--primary-lighter);
}

.custom-button.secondary {
  background-color: var(--secondary-lighter);
  color: var(--primary);
}

.custom-button.secondary:hover,
.custom-button.secondary.disabled,
.custom-button.secondary:disabled {
  background-color: var(--secondary);
}

.custom-button.danger {
  background-color: var(--red);
}

.custom-button.danger:hover,
.custom-button.danger.disabled,
.custom-button.danger:disabled {
  background-color: var(--red-lighter);
}

.custom-button.outlined {
  background-color: var(--white);
  color: var(--primary);
  border: 1px solid var(--primary);
}

.custom-button.outlined:hover {
  background-color: var(--background);
}

.custom-button.link {
  background: white;
  text-decoration: underline;
  color: var(--primary);
  border: none;
  padding: 0;
}

.custom-button.link:hover {
  background: white;
}

.custom-button.full-width {
  width: 100%;
  display: flex;
  justify-content: center;
}

.custom-button.fit-content {
  width: fit-content;
}

.custom-button.fit-height {
  height: min-content;
}

.custom-button:hover {
  transition: all 0.1s linear;
}

.custom-button.disabled,
.custom-button:disabled {
  cursor: not-allowed;
}
