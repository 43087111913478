.dashboard-statistics {
  gap: 25px;
}

.dashboard-statistics .statistics {
  display: flex;
  flex-wrap: wrap;
}

.dashboard-statistics .statistics .item {
  background-color: var(--background);
  border-radius: 10px;
  margin: 15px;
  padding: 15px;
  min-height: 150px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-statistics .statistics .item .title {
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  color: var(--primary);
}

.dashboard-statistics .statistics .item .value {
  font-size: 32px;
  text-align: center;
  color: var(--gray-dark);
}

@media (min-width: 1000px) {
  .dashboard-statistics .statistics .item {
    flex-basis: calc(33% - 90px);
  }
}

@media (max-width: 999px) {
  .dashboard-statistics .statistics .item {
    flex-basis: calc(100%);
  }
}
