.property-list .navigation-wrapper {
    display: flex;
    gap: 12px;
}

.property-list .header {
    display: flex;
}

.property-list .header .image {
    flex: 0.2;
    max-width: 250px;
}

.property-list .header .items {
    flex: 0.8;
    display: flex;
    padding: 25px 0 25px 12px;
    gap: 10px
}

.property-list .header .item {
    flex: 1;
    color: var(--primary);
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    transition: all 0.2s linear;
    text-align: center;
}

.property-list .header .item.no-click {
    cursor: default;
}

.property-list .header .item.highlight {
    position: relative;
    color: var(--secondary);
    z-index: 2;
}

.property-list .header .item.highlight .badge {
    position: absolute;
    width: calc(100% + 14px);
    height: calc(100% + 6px);
    left: -7px;
    top: -2px;
    border-radius: 25px;
    background-color: var(--primary);
    z-index: -1;
}

.property-list .header .item svg {
    margin-left: 5px;
    width: 20px;
    height: 20px;
}

.property-list .property-item .image {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.property-list .content .item .status {
    background-color: var(--primary);
    color: var(--white);
    border-radius: 25px;
    padding: 2px 10px 4px 10px;
    display: flex;
    justify-content: center;
    width: fit-content;
}

.property-list .content .item .category {
    color: var(--primary);
    font-weight: 400;
    font-size: 18px;
}

.property-list .content .item .tuple {
    display: flex;
    align-items: center;
}

.property-list .content .item .tuple svg {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.property-list .content .item.edit {
    display: flex;
    gap: 2px;
    height: fit-content;
}

.property-list .content .item.edit .icon {
    padding: 4px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    transition: background-color 0.15s linear;
}

.property-list .content .item.edit .icon:hover {
    background-color: var(--secondary-lighter);
}

.property-list .content .item.edit svg {
    cursor: pointer;
    width: 25px;
    height: 25px;
}

.property-list .property-item {
    display: flex;
    background-color: var(--background);
    margin-bottom: 15px;
    border-radius: 10px;
    cursor: pointer;
    height: 130px;
}

.property-list .property-item .image {
    flex: 0.2;
    max-width: 250px;
    position: relative;
}

.property-list .property-item .image .favourite {
    position: absolute;
    top: 15px;
    left: 15px;
    background-color: var(--primary);
    border-radius: 25px;
    padding: 2px 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.property-list .property-item .image .favourite svg {
    width: 20px;
    height: 20px;
}

.property-list .property-item .image .favourite.active svg {
    width: 20px;
    height: 20px;
    fill: var(--secondary);
}

.property-list .property-item .image img {
    width: 100%;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    height: 100%;
    object-fit: cover;
}

.property-list .property-item .content {
    flex: 0.8;
    display: flex;
    padding: 25px 0 25px 12px;
}

.property-list .property-item .content .item {
    flex: 1;
    color: var(--dark);
    padding-right: 10px;
}

.property-list .property-item .content .item.highlight {
    font-weight: 400;
    transform: scale(1.1);
}

.property-list .property-item .content .item .price {
    color: var(--primary);
    font-weight: 500;
}

.property-list .property-item .content .item .commission {
    color: var(--dark);
}

.property-list .property-item {
    display: flex;
    background-color: var(--background);
    margin-bottom: 15px;
    border-radius: 10px;
}
