.agency-information-form .title {
  font-size: 24px;
  font-weight: 400;
  color: var(--gray-dark);
  margin-bottom: 12px;
}

.agency-information-form .agency-form .horizontal-input {
  display: flex;
  gap: 36px;
}

@media (max-width: 600px) {
  .agency-information-form .agency-form .horizontal-input {
    flex-direction: column;
    gap: 4px;
  }
}
