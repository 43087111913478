.user-list {
}

.user-list .navigation {
  display: flex;
}

.user-list .header {
  display: flex;
  padding:  24px 12px 12px;
  border-bottom: 1px solid var(--gray-lighter);
}

.user-list .header .items {
  flex: 1;
  display: flex;
}

.user-list .header .item {
  color: var(--primary);
  font-weight: 500;
  display: flex;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.user-list .user-item {
  display: flex;
  border-bottom: 1px solid var(--gray-lighter);
  transition: background-color 0.1s linear;
}

.user-list .user-item:hover {
  background: var(--background);
}

.user-list .user-item .content {
  flex: 1;
  display: flex;
  padding: 12px;
  min-height: 46px;
}

.user-list .item.small {
  flex: 0.1;
}

.user-list .item.medium {
  flex: 0.2;
}

.user-list .item.large {
  flex: 0.3;
}

.user-list .user-item .content .item {
  color: var(--dark);
  display: flex;
  align-items: center;
}

.user-list .content .item.edit {
  gap: 10px;
}

.user-list .content .item.edit svg {
  cursor: pointer;
  width: 25px;
  height: 25px;
  padding: 4px;
  border-radius: 4px;
  transition: background-color 0.15s linear;
}

.user-list .content .item.edit svg:hover {
  background-color: var(--white);
}

@media screen and (max-width: 750px){
  .user-list .user-list-wrapper {
    overflow: auto;
  }

  .user-list .user-item .content .item {
    word-break: break-word;
  }

  .user-list .header .items, .user-list .user-item .content {
    flex: unset;
    gap: 10px;
  }

  .user-list .item.small {
    flex: unset;
    width: 60px;
  }

  .user-list .item.medium {
    flex: unset;
    width: 100px;
  }

  .user-list .item.large {
    flex: unset;
    width: 150px;
  }

  .user-list .header, .user-list .user-item {
    width: fit-content;
  }
}