.header-wrapper {
  padding: 30px;
}

.header-wrapper .header {
  align-items: center;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}

.header-wrapper .header .logo {
  fill: var(--primary);
}

.header-wrapper .header .logo svg {
  width: 200px;
}

.add-user-dialog-content .email-input {
  margin-bottom: 12px;
  padding: 6px 12px;
}

.add-user-dialog-content .button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.header-links-mobile {
  width: 100%;
}

@media screen and (min-width: 1170px) {
  .header-links-mobile {
    display: none !important;
  }
}

@media screen and (max-width: 1170px) {
  .header-links {
    display: none !important;
  }
}
