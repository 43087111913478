.title-wrapper {
  display: flex;
  margin-bottom: 15px;
  width: 100%;
  justify-content: space-between;
}

.title-wrapper .title {
  color: var(--primary);
  font-weight: 300;
  font-size: 28px;
}

.title-wrapper .manual-link {
  justify-self: end;
}

.error-list-item {
  margin-bottom: 16px;
}

.property-ref {
  font-weight: bold;
}
