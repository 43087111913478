.password-reset-container {
  justify-content: center;
  width: 100%;
  margin: auto 20px;
}

.password-reset-upper-container {
  height: 100%;
}

.login-link {
  color: var(--primary);
  font-size: 14px;
}

.password-reset-form {
  max-width: 300px;
  padding: 75px;
  background-color: var(--white);
  border-radius: 25px;
}

.password-reset-form form {
  display: flex;
  flex-direction: column;
}

.password-reset-form .description {
  margin: 25px 0;
}

.password-reset-form input {
  background-color: var(--white);
}

@media screen and (max-width: 499px) {
  .password-reset-container {
    flex-direction: column;
  }

  .password-reset-form {
    padding: 50px;
  }
}
