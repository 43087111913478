.personal-home .page-content-no-margin .statistics {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 30px;
}

.personal-home .page-content-no-margin .statistics .item {
  background-color: var(--background);
  border-radius: 10px;
  padding: 15px;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.personal-home .page-content-no-margin .statistics .item.active {
  background-color: var(--primary);
  color: var(--background)
}

.personal-home .page-content-no-margin .statistics .item .item-image {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.personal-home .page-content-no-margin .statistics .item .item-number {
  font-weight: 400;
  font-size: 23px;
}

@media (min-width: 1000px) {
  .personal-home .page-content-no-margin .statistics .item {
    flex-basis: calc(33% - 90px);
  }
}

@media (max-width: 999px) {
  .personal-home .page-content-no-margin .statistics .item {
    flex-basis: calc(100%);
  }
}
