.account-confirmation-container {
  justify-content: center;
  width: 100%;
  margin: auto 20px;
}

.account-confirmation-upper-container {
  height: 100%;
}

.account-confirmation-form {
  max-width: 300px;
  padding: 75px;
  background-color: var(--white);
  border-radius: 25px;
}

.account-confirmation-form form {
  display: flex;
  flex-direction: column;
}

.account-confirmation-form .description {
  margin: 25px 0;
}

.account-confirmation-form .description.error {
  background-color: var(--alert-bg);
  padding: 12px;
  color: var(--red-darker);
  border-radius: 6px;
}

.account-confirmation-form input {
  background-color: var(--white);
}

.account-confirmation-form .tooltip-title {
  font-weight: 400;
  font-size: 16px;
  color: var(--primary);
  margin-bottom: 5px;
}

.account-confirmation-form .tooltip-text {
  font-size: 14px;
}

@media screen and (max-width: 500px) {
  .account-confirmation-container {
    flex-direction: column;
  }

  .account-confirmation-form {
    padding: 50px;
  }
}
