.login-container {
  justify-content: center;
  width: 100%;
  margin: auto 20px;
}

.login-upper-container {
  height: 100%;
}

.password-reset-link {
  color: var(--primary);
  font-size: 14px;
}

.img-show-password {
  width: 25px;
  margin-top: -40px;
  margin-right: -300px;
  z-index: 100000;
}

.login-form {
  max-width: 300px;
  padding: 75px;
  background-color: var(--white);
  border-radius: 25px;
}

.login-form input {
  background-color: var(--white);
}

.login-form svg {
  margin: 0 0 50px 0;
  fill: var(--primary);
}

@media screen and (max-width: 499px) {
  .login-container {
    flex-direction: column;
  }

  .login-form {
    padding: 50px
  }
}
