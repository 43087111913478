.client-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
  align-items: flex-start;
}

.client-form .row {
  display: flex;
  gap: 24px;
  width: 100%;
}

.client-form .row .input-field {
  width: 200px;
}

@media screen and (max-width: 911px) {
  .client-form .row {
    gap: 6px;
  }

  .client-form .row .input-field {
    width: 100%;
  }
}
