.registration-list .header {
  display: flex;
  padding: 12px;
  border-bottom: 1px solid var(--gray-lighter);
}

.registration-list .header .items {
  flex: 1;
  display: flex;
}

.registration-list .header .item {
  flex: 1;
  color: var(--primary);
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.registration-list .registration-item {
  display: flex;
  border-bottom: 1px solid var(--gray-lighter);
  transition: background-color 0.1s linear;
}

.registration-list .registration-item:hover {
  background: var(--background);
}

.registration-list .registration-item .content {
  flex: 1;
  display: flex;
  padding: 12px 12px;
  min-height: 46px;
}

.registration-list .registration-item .content .item {
  flex: 1;
  color: var(--dark);
  line-break: anywhere;
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.registration-list .registration-item .content svg {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  padding: 3px;
  transition: background-color 0.1s linear;
}
.registration-list .registration-item .content svg:hover {
  background-color: var(--white);
  cursor: pointer;
}

.registration-list .registration-item .content svg.cross {
  margin-top: 2px;
  margin-left: 2px;
}

.registration-list .registration-item .content svg.cross * {
  stroke: var(--red);
  fill: var(--red);
}