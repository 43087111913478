.layout-toggle {
  display: flex;
  background-color: var(--background);
  border-radius: 50px;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}

.layout-toggle .toggle-item.active {
  background-color: var(--primary);
  border-radius: 50px;
}

.layout-toggle .toggle-item svg {
  width: 20px;
  height: 20px;
  margin: 10px 20px;
  padding-top: 2px;
}
