.select-group {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  width: 100%;
  padding: 15px 0 0;
  margin-top: 3px;
  border-bottom: 1px solid var(--gray);
}

.select-group.clean {
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.select-group .select-field {
  border: none;
  padding: 4px 4px;
  width: 100%;
  margin-top: 10px;
  -webkit-appearance: none;
}

.select-group .select-field.clean {
  margin: 0;
  background-color: transparent;
}

.select-group:focus-within {
  border-bottom: 3px solid var(--gray);
  margin-bottom: -2px;
}

.select-group .select-field:focus,
.select-group .select-field:focus-visible {
  outline: none;
}

.select-group .select-field ~ .label {
  position: absolute;
  left: 0;
  display: block;
  transition: 0.2s;
  color: var(--gray-dark);
  font-size: 18px;
  cursor: text;
  top: 0;
}

.select-group .select-field:focus ~ .label {
  position: absolute;
  display: block;
  transition: 0.2s;
  font-size: 18px;
  font-weight: 700;
  top: 0;
}

.select-group svg.chevron-down {
  width: 10px;
  height: 10px;
  padding-right: 6px;
  margin-top: 3px;
}