.graph {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.graph .graph-title {
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: var(--primary);
  font-weight: 600;
}

.graph .graph-wrapper {
  position: relative;
  width: 100%;
  height: 240px;
  overflow: hidden;
}
