.user-registration-upper-container {
  height: 100%;
}

.user-registration-container {
  justify-content: center;
  width: 100%;
  margin: auto 20px;
}

.user-registration-container .title {
  padding: 12px 0;
  font-weight: 400;
  font-size: 24px;
  color: var(--primary);
}

.user-registration-container .user-registration-form {
  max-width: 400px;
  width: 100%;
  padding: 75px;
  background-color: var(--white);
  border-radius: 25px;
}

.user-registration-form .form {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.user-registration-form .form .horizontal-input {
  display: flex;
  gap: 8px;
}

.user-registration-form .form .tooltip-title {
  font-weight: 400;
  font-size: 16px;
  color: var(--primary);
  margin-bottom: 5px;
}

.user-registration-form .form .tooltip-text {
  font-size: 14px;
}

@media (max-width: 600px) {
  .user-registration-upper-container {
    height: max-content;
  }

  .user-registration-container {
    height: min-content;
    margin: 30px;
  }

  .user-registration-container .user-registration-form {
    padding: 50px;
  }

  .user-registration-form .form .horizontal-input {
    flex-direction: column;
    gap: 4px;
  }

  .user-registration-form .form .phone-number {
    width: 100%;
    margin-right: 0;
  }
}
