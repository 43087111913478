.header-wrapper .header .header-links .icon {
  width: 25px;
  height: 25px;
  fill: var(--primary);
}

.header-wrapper .header .header-links {
  gap: 30px;
  display: flex;
  align-items: center;
}

.header-wrapper .header-links .dropdown-container {
  position: relative;
}

.header-wrapper .header-links .dropdown {
  position: absolute;
  top: 38px;
  right: 0;
  background-color: var(--white);
  color: var(--primary);
  border-radius: 5px;
  min-width: 160px;
  font-weight: 300;
  text-align: start;
  box-shadow: 0 0 6px 3px var(--gray-lighter);
  z-index: 10;
}

.header-wrapper .header-links .dropdown .title-item {
  font-weight: 400;
  padding: 6px 12px;
  border-bottom: 1px solid var(--gray-lighter);
}

.header-wrapper .header-links .dropdown .item {
  cursor: pointer;
  padding: 6px 12px;
}

.header-wrapper .header-links .dropdown .item:hover {
  background-color: var(--broken-white);
  border-radius: 5px;
}

.header-wrapper .header .header-links .icon-wrapper {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-wrapper .header .header-links .icon-wrapper.active {
  background: var(--white);
  border-radius: 50%;
}

.header-wrapper .header .header-links .icon-wrapper:hover {
  background: var(--white);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.15s ease-in;
}

.header-wrapper .header .header-links .link {
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
  font-size: large;
  display: flex;
  align-items: center;
  font-weight: 400;
  flex-shrink: 0;
  transition: all 0.15s ease-in-out;
  border-bottom: 1px solid transparent;
}

.header-wrapper .header .header-links .link:hover,
.header-wrapper .header .header-links .link.active {
  border-bottom: 1px solid var(--primary);
}
