.payed-container {
  justify-content: center;
  width: 100%;
  margin: auto 20px;
}

.payed-upper-container {
  height: 100%;
}

.payed-form {
  max-width: 300px;
  width: 100%;
  padding: 75px;
  background-color: var(--white);
  border-radius: 25px;
}

.payed-form .payed-message {
  padding: 12px;
}

@media screen and (max-width: 500px) {
  .payed-container {
    flex-direction: column;
  }
}
