.range-wrapper .input-wrapper  {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.range-wrapper .input-wrapper .range-label {
  margin-right: 10px;
  width: 35px;
}

.range-wrapper .input-wrapper .range-input {
  width: 75px;
  height: fit-content;
  outline: none;
  font-size: 14px;
  margin-left: 12px;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #999;
  -moz-appearance: textfield;
  appearance: textfield;
}
