.document-preview {
  margin: 5px 0;
  display: flex;
  gap: 8px;
}

.document-preview .remove-button {
  width: 20px;
  height: 20px;
  padding: 2px;
  background-color: var(--gray);
  border-radius: 50%;
}

.document-preview .remove-button:hover {
  background-color: var(--gray-lighter);
  cursor: pointer;
  transition: background-color 0.2s linear;
  transform: scale(1.05);
}

.document-preview a {
 color: var(--dark);
}
