.payment-confirmation-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto 20px;
}

.payment-confirmation-upper-container {
  display: flex;
  height: 100%;
}

.payment-confirmation {
  max-width: 350px;
  padding: 75px;
  background-color: var(--white);
  border-radius: 25px;
}

.payment-confirmation svg {
  width: 100%;
}

.payment-confirmation .description {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.payment-confirmation .description svg {
  width: 40px;
  height: 40px;
  fill: var(--primary);
  stroke: var(--primary);
}

.payment-confirmation .description svg.loading-spinner {
  width: 25px;
  height: 25px;
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.payment-confirmation .description.error {
  background-color: var(--alert-bg);
  padding: 12px;
  color: var(--red-darker);
  border-radius: 6px;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

