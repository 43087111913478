.image-preview {
  position: relative;
  border: 1px solid var(--gray-lighter);
  box-shadow: 0 0 4px 2px var(--gray-lighter);
}

.image-preview img {
  width: 200px;
  height: 150px;
  object-fit: cover;
  user-select: none;
}

.image-preview .remove-button {
  position: absolute;
  width: 20px;
  height: 20px;
  padding: 2px;
  background-color: var(--gray-lighter);
  border-radius: 50%;
  top: -8px;
  right: -8px;
}

.image-preview .remove-button:hover {
  background-color: var(--gray);
  cursor: pointer;
  transition: background-color 0.2s linear;
  transform: scale(1.05);
}