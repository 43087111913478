.agent-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.agent-info dl {
  display: grid;
  grid-template-columns: max-content auto;
}

.agent-info dt {
  font-weight: bold;
  grid-column-start: 1;
}

.agent-info dd {
  grid-column-start: 2;
}

.agent-info a {
  color: var(--primary);
}