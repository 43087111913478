.property-grid {
  width: 100%;
}

.property-grid .navigation-wrapper {
  display: flex;
  gap: 12px;
}

.property-grid .properties {
  margin-top: 25px;
}

.property-grid .properties {
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
}

.property-grid .property-item {
  background-color: var(--background);
  max-width: 400px;
  border-radius: 10px;
  flex: 1;
  cursor: pointer;
}

.property-grid .navigation-wrapper .ref-search .ref-input {
  margin-right: 1rem;
}

.property-grid .property-item .image {
  display: flex;
  max-height: 200px;
  position: relative;
}

.property-grid .property-item .image .favourite {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: var(--primary);
  border-radius: 25px;
  padding: 2px 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.property-grid .property-item .image .status {
  position: absolute;
  bottom: 15px;
  left: 15px;
  background-color: var(--pink);
  color: var(--primary);
  font-weight: 500;
  border-radius: 25px;
  padding: 2px 10px 4px 10px;
  display: flex;
  align-items: center;
  text-transform: lowercase;
}

.property-grid .property-item .image .favourite svg {
  width: 20px;
  height: 20px;
}

.property-grid .property-item .image .favourite.active svg {
  width: 20px;
  height: 20px;
  fill: var(--secondary);
}

.property-grid .property-item .image img {
  height: 175px;
  width: 100%;
  object-fit: cover;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.property-grid .property-item .content {
  padding: 0 10px 15px;
}

.property-grid .property-item .content .item {
  color: var(--dark);
  line-break: anywhere;
}

.property-grid .property-item .content .item .price {
  color: var(--primary);
  font-size: 22px;
  font-weight: 500;
}

.property-grid .property-item .content .item .commission {
  display: flex;
  align-items: center;
  color: var(--dark);
}

.property-grid .property-item .content .item.category {
  color: var(--primary);
  font-size: 18px;
  font-weight: 400;
}

.property-grid .property-item .content .item.area {
  color: var(--dark);
}

.property-grid .property-item .content .item.details {
  display: flex;
  border-top: 1px solid var(--gray);
  padding-top: 15px;
  margin-top: 15px;
}

.property-grid
  .property-item
  .content
  .item.details
  .reference
  .agency
  .agency-name {
  color: var(--primary);
  font-weight: 400;
  margin-right: 5px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
}

.property-grid .property-item .content .item.details .reference {
  flex: 0.65;
}

.property-grid .property-item .content .item.details .property-details {
  flex: 0.35;
}

.property-grid .property-item .content .item.details .property-details .tuple {
  display: flex;
  align-items: center;
}

.property-grid
  .property-item
  .content
  .item.details
  .property-details
  .tuple
  svg {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.property-grid .header .item {
  flex: 1;
  color: var(--primary);
  font-weight: 500;
  cursor: pointer;
}

@media (min-width: 1600px) {
  .property-grid .property-item {
    flex-basis: calc(25% - 3*28px/4);
    max-width: calc(25% - 3*28px/4);
  }

  .property-grid .property-item .content .item.details .reference {
    flex: 0.6;
  }

  .property-grid .property-item .content .item.details .property-details {
    flex: 0.4;
  }

}

@media (max-width: 1599px) {
  .property-grid .property-item {
    flex-basis: calc(33.33% - 2*28px/3);
    max-width: calc(33.33% - 2*28px/3);
  }

}

@media (max-width: 1380px) {
  .property-grid .property-item {
    flex-basis: calc(50% - 28px/2);
    max-width: calc(50% - 28px/2);
  }

  .property-grid .property-item .content {
    padding: 0 25px 20px;
  }
}

@media (max-width: 750px) {
  .property-grid .property-item {
    flex-basis: calc(100%);
    max-width: calc(100%);
  }

  .property-grid .navigation-wrapper {
    flex-direction: column;
  }

  .property-grid .navigation-wrapper .ref-search {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
    flex-direction: column;
    gap: 10px;
  }

  .property-grid .navigation-wrapper .ref-search .ref-input {
    margin-right: 0;
  }

}